import { combineReducers } from "redux";
import auth from './auth.reducers';
import storage from "./storage.reducer";
import dashboardStates from './dashboard.reducers';

const rootReducer = combineReducers({
    auth,
    storage,
    dashboardStates
});

export default rootReducer;
