import React, { memo, useEffect } from "react";
import ReactDOM from "react-dom";
import { v4 as uuid } from "uuid";

const modalRoot = document.getElementById("portal");

function ModalPopup(props) {
  const { id, className = "", isReferralView = false } = props;
  const modalId = id ? id : uuid();
  useEffect(() => {
    let body = document.querySelector("body");
    body.style.overflow = "hidden";
    return () => {
      let popup = document.getElementsByClassName("ModalPopup");
      if (popup.length > 0) return;
      body.style.overflow = "initial";
    };
  }, []);
  return ReactDOM.createPortal(
    <div
      id={modalId}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 3,
        overflow: "hidden",
        backgroundColor: "rgba(79, 79, 79, 0.31)",
        ...props.styles,
        overflowY: isReferralView && "auto",
      }}
      className={`ModalPopup flex-center ${className}`}
      onMouseDown={(e) => {
        if (e.target.classList.contains("ModalPopup")) {
          e.stopPropagation();
          props.onModalTapped && props.onModalTapped();
        }
      }}
    >
      {props.children}
    </div>,
    modalRoot
  );
}
export default memo(ModalPopup);
