import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
//import Login from './viewpages/Login'
import NorthwellSamlLogin from "./viewpages/NorthwellSamlLogin";
import DemoLoginLog from "./viewpages/DemoLoginLog";
import DefaultLayout from "./layout/DefaultLayout";
//import FirebaseSignUp from './viewpages/FirebaseSignUp'
//import OTPSend from './viewpages/OTPSend';
import { Provider } from "react-redux";
import { store } from "./redux/store";
import PasswordResetPage from "./viewpages/PasswordResetPage";
import * as dasboardActions from "../src/redux/actions/dashboard.action";

import NewPortalIntro from "./newWebPortalPopUp/popUpContent";

/* 
route will execute from here 
redux store is bind here
*/
var Root = () => {
  useEffect(() => {
    store.dispatch(
      dasboardActions.setInvitePatient({
        invitePatientData: { isInvitePatient: false, dataObj: null },
      })
    );
  }, []);
  return (
    <Provider store={store}>
      <NewPortalIntro />
      <Router>
        <Switch>
          {/* <Route exact path="/otp_verification" component={OTPSend}></Route> */}
          {/* <Route exact path="/finishSignUp" component={FirebaseSignUp}></Route> */}
          {/* <Route exact path='/reset-password' component={PasswordResetPage} /> */}
          <Route exact path="/reset-password" component={PasswordResetPage} />
          <Route exact path="/login" component={NorthwellSamlLogin} />
          <Route exact path="/login-log" component={DemoLoginLog} />

          {/* <Route exact path='/samllogin' component={NorthwellSamlLogin} /> */}
          <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />}></Route>
        </Switch>
      </Router>
    </Provider>
  );
};

export default Root;
