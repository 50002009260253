import React from "react";
import "./switch.css";
import { connect } from "react-redux";

import cookie from "react-cookies";

function Switch({ title, userCredentials }) {
  const user = JSON.parse(userCredentials?.northwelluser) || null;
  const trylater = () => {
    var someDate = new Date();
    var result = new Date(someDate.setDate(someDate.getDate() + 2));
    cookie.save("onBoarded", "true", { expires: result });
  };
  return (
    <div
      onClick={() => {
        trylater();
        let url = `${process.env.REACT_APP_NEW_PORTAL_URL}/onboarding?key=${user.user.stsTokenManager.accessToken}`;
        window.location.assign(url);
      }}
      className="d-flex align-items-center"
    >
      <div className="switch-label">{title}</div>
      <label className="switch">
        <input type="checkbox" checked={false} />
        <span className="slider round"></span>
      </label>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    userCredentials: state.auth,
  };
};

export default connect(mapStateToProps, null)(Switch);
