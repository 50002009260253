import React, { useEffect, useState } from "react";
import ModalPopup from "./newPortalPopUp";
import "./newPortal.css";
import slide from "../gif/slide.gif";
import cookie from "react-cookies";
import { connect } from "react-redux";
import crossButton from "./Close.svg";
function NewPortalIntro(props) {
  useEffect(() => {
    getCookie();
  }, []);
  const getCookie = () => {
    const cookies = cookie.load("onBoarded");
    if (cookies && cookies === "true") return;
    setTimeout(() => {
      setShow(true);
    }, 2000);
  };

  const trylater = () => {
    var someDate = new Date();
    var result = new Date(someDate.setDate(someDate.getDate() + 2));
    cookie.save("onBoarded", "true", { expires: result });
    setShow(false);
  };

  const [show, setShow] = useState(false);
  const userCredentials = JSON.parse(props?.userCredentials.northwelluser) || null;
  return (
    <>
      {show && userCredentials?.user && (
        <ModalPopup
          onModalTapped={() => {
            // setShow(false);
          }}
        >
          <div className="main-popup-div popup">
            <div className="div-containing-label">
              Introducing New Playback Experience
              <div onClick={trylater} className="cross-button">
                <img src={crossButton} alt="cross-button" />
              </div>
            </div>
            <div className="div-containing-img">
              <img src={slide} alt="gif-popup" className="gif-image" />
            </div>
            <div className="div-containing-button">
              <button
                onClick={() => {
                  localStorage.setItem("onBoarding", "true");
                  trylater();
                  let url = `${process.env.REACT_APP_NEW_PORTAL_URL}/onboarding?key=${userCredentials.user.stsTokenManager.accessToken}`;
                  window.location.assign(url);
                }}
                className="button-custom"
              >
                Try now
              </button>
              <div onClick={trylater} className="button-custom-try">
                Try Later
              </div>
            </div>
          </div>
        </ModalPopup>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userCredentials: state.auth,
  };
};

export default connect(mapStateToProps, null)(NewPortalIntro);
