import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as actions from "../redux/actions/auth.action";
import { bindActionCreators } from "redux";
import LoadingIndicator from "../common/LoadingIndicator";
import "./passwordReset.css";
import * as resetmodel from "./viewModels/reset-password-vm";
import OtpInput from "react-otp-input";
import InputMask from "react-input-mask";
import * as i18n from "../I18n/en.json";
import * as firebase from "firebase/app";
import "firebase/firestore";
import StatusView from "../components/StatusView/StatusView";
import Socket, { socketActions, socketSubActions } from "../helper/Websocket";
import { passwordStrength } from "check-password-strength";
const defaultOptions = [
  { id: 0, value: "Too weak", minDiversity: 0, minLength: 0 },
  { id: 1, value: "Weak", minDiversity: 1, minLength: 6 },
  { id: 2, value: "Medium", minDiversity: 2, minLength: 8 },
  { id: 3, value: "Strong", minDiversity: 4, minLength: 10 },
];

let ResetPasswordView = (props) => {
  let { state, onchange, onConfChange, onContinue, message, messageType } =
    props;
  const strength = useMemo(
    () => passwordStrength(state.password, defaultOptions),
    [state.password]
  );
  const [pswdstate, setState] = useState({
    passwordError: null,
    confPasswordError: null,
  });
  return (
    <>
      <h3>Reset Password</h3>
      {message ? (
        <StatusView
          message={message}
          type={messageType}
          showCloseButton={false}
        />
      ) : null}
      <div className="form-group">
        <input
          value={state.password}
          autoComplete="off"
          autoFocus
          className="login-input"
          placeholder="New Password"
          type="password"
          onChange={onchange}
        />
        <label className="error-label">
          {strength.id <= 1 && state.password !== ""
            ? "Password is " +
              strength.value +
              ". It must be of atleast 8 characters and contain any of 2 cases - lowercase, uppercase, special character and number"
            : pswdstate.passwordError}
        </label>
      </div>
      <div className="form-group">
        <input
          value={state.confPassword}
          autoComplete="off"
          className="login-input"
          placeholder="Confirm Password"
          type="password"
          onChange={onConfChange}
        />
        <label className="error-label">{pswdstate.confPasswordError}</label>
      </div>
      <button
        className="btn btn-blue-block"
        disabled={strength <= 1 || state.confPassword === ""}
        onClick={() => {
          if (strength.id <= 1) {
            return;
          } else if (state.password !== state.confPassword) {
            setState({
              ...pswdstate,
              confPasswordError:
                "Confirm password does not match the above password",
            });
          } else {
            onContinue && onContinue(state.password);
          }
          // if (
          //   state.password === state.confPassword &&
          //   state.password.length >= 6
          // ) {
          //   onContinue(state.password);
          // } else if (state.password < 6) {
          //   setState({
          //     passwordError: "Password should be atleast 6 characters long",
          //   });
          // } else if (state.password !== state.confPassword) {
          //   setState({
          //     confPasswordError:
          //       "Confirm password does not match the above password",
          //   });
          // }
        }}
      >
        Continue
      </button>
    </>
  );
};

let MobileNumberView = (props) => {
  return (
    <>
      <h3>Enter Mobile Number</h3>
      {props.mobileError ? (
        <StatusView
          message={props.mobileError}
          type="error"
          showCloseButton={false}
        />
      ) : null}
      <div className="form-group">
        <InputMask
          mask="+1 (999) 999-9999"
          value={props.value}
          onChange={props.onChange}
        >
          {(inputProps) => (
            <input
              autoFocus
              className="login-input"
              placeholder="e.g. +1 (212) 212-1212"
              {...inputProps}
              type="text"
            />
          )}
        </InputMask>
      </div>
      {props.captchaVerify ? (
        <LoadingIndicator />
      ) : (
        <button
          className="btn btn-blue-block"
          disabled={props.value.length !== 11}
          onClick={() => props.continueTapped()}
        >
          Continue
        </button>
      )}
    </>
  );
};

let OtpView = (props) => {
  return (
    <>
      <StatusView
        message={props.message}
        type={props.type}
        showCloseButton={false}
      />
      <div className="form-group otp-center">
        <OtpInput
          inputStyle="form-control otp-input"
          numInputs={6}
          separator={<span className="otp-span">-</span>}
          onChange={(otp) => props.otpEntered(otp)}
          otpType="number"
          value={props.otp}
          autoFocus
        />
      </div>
      <div className="did-not-get flex-center">
        {" "}
        Didn’t get code yet?{" "}
        <div className="resend-box flex-center">
          <button className="resend-link" onClick={() => props.reSendOTP()}>
            {i18n && i18n.login && i18n.buttontext.resendOTP}
          </button>
          <img src="/assets/images/arrow-right-vector.svg" />
        </div>
      </div>
      <button
        className="btn btn-blue-block"
        disabled={props.otp.length !== 6}
        onClick={() => props.continueTapped()}
      >
        Continue
      </button>
    </>
  );
};

function PasswordResetPage(props) {
  const [state, setstate] = useState({
    passwordConfirmed: false,
    showPassword: false,
    password: "",
    confPassword: "",
    userdetails: null,
    compErrorMsg: null,
    compSuccessMsg: null,
    enableMobile: sessionStorage.getItem("enableMobAuth") || false,
    mobileNumber: "",
    mobileError: null,
    captchaVerify: false,

    error: null,
    isloading: true,
    enableOtp: false,
    otpMsg: "",
    otpError: null,
    otp: "",

    message: null,
    messageType: null,

    userId: null,

    socket: null,
  });
  useEffect(() => {
    const searchParams = new URLSearchParams(props.location.search);
    let base64 = searchParams.get("data");
    if (base64.length <= 1 || base64 === "") {
      props.history.push("/");
    } else {
      let usrCreds = resetmodel.decodeCredentials(base64);
      firebase
        .auth()
        .signInWithEmailAndPassword(usrCreds.email, usrCreds.password)
        .then(() => {
          setstate({
            ...state,
            isloading: false,
            userdetails: usrCreds,
            password: state.enableMobile ? usrCreds.password : "",
            // socket: new Socket(),
          });
        })
        .catch((error) => {
          console.log("reset-password error ", error);
          props.history.push("/");
        });
    }
    return () => {
      if (state.socket?.socket) {
        state.socket.socket.close();
      }
    };
  }, []);

  function getView() {
    let view = "";
    if (state.enableOtp) {
      let message =
        "We send you a text on phone ending in +*******" +
        state.mobileNumber.substring(8, 12) +
        ". Please enter the code below";
      view = (
        <OtpView
          message={state.otpError ? state.otpError : message}
          type={state.otpError ? "error" : "success"}
          otp={state.otp}
          otpEntered={(otp) => setstate({ ...state, otp: otp })}
          continueTapped={() => {
            setstate({ ...state, isloading: true, otpError: null });
            let dataStr = JSON.stringify({
              action: socketActions.auth,
              subAction: socketSubActions.verifyProviderOTP,
              enteredOtp: state.otp,
              userId: state.userId,
            });
            window.socket.send(dataStr, (data) => {
              if (data.settings?.status === 1) {
                firebase
                  .auth()
                  .signInWithCustomToken(data.data.customToken)
                  .then((firebaseUser) => {
                    storeDataRedux(firebaseUser, { user: data.data.userData });
                    setstate({ ...state, isloading: false });
                    sessionStorage.setItem("enableDetailsPage", true);
                    props.history.push("/details");
                  })
                  .catch((error) => {
                    setstate({
                      ...state,
                      isloading: false,
                      otpError: error.message,
                      enableOtp: true,
                    });
                  });
              } else {
                setstate({
                  ...state,
                  isloading: false,
                  otpError: data.settings?.message || "Something went wrong",
                  enableOtp: true,
                });
              }
            });
          }}
          reSendOTP={sendOtp}
        />
      );
    } else if (state.enableMobile) {
      view = (
        <MobileNumberView
          mobileError={state.mobileError}
          value={state.mobileNumber}
          captchaVerify={state.captchaVerify}
          onChange={(e) =>
            setstate({
              ...state,
              mobileNumber: e.target.value.replace(/[^0-9]/g, ""),
            })
          }
          continueTapped={() => sendOtp()}
        />
      );
    } else {
      view = (
        <ResetPasswordView
          state={{ ...state }}
          message={state.message}
          messageType={state.messageType}
          onchange={(e) => setstate({ ...state, password: e.target.value })}
          onConfChange={(e) =>
            setstate({ ...state, confPassword: e.target.value })
          }
          onContinue={(password) => {
            setstate({ ...state, isloading: true });
            resetmodel.updatePassword(
              password,
              () => {
                setstate({ ...state, enableMobile: true });
              },
              (error) => {
                setstate({
                  ...state,
                  isloading: false,
                  message: error.message,
                  messageType: "error",
                });
              }
            );
          }}
        />
      );
    }
    return view;
  }

  function sendOtp() {
    setstate({ ...state, isloading: true });
    let str = JSON.stringify({
      action: socketActions.auth,
      subAction: socketSubActions.validateProvider,
      emailId: state.userdetails.email,
      password: state.password,
      mobileNo: "+" + state.mobileNumber,
    });
    window.socket.send(str, (data) => {
      if (data.settings?.status === 1) {
        setstate({ ...state, enableOtp: true, userId: data.data.userId });
      } else {
        setstate({
          ...state,
          isloading: false,
          mobileError: data.settings?.message || "Something went wrong",
          messageType: "error",
        });
      }
    });
  }

  let storeDataRedux = (firebaseUser, data) => {
    props.savenorthwelluserobj(JSON.stringify(firebaseUser));
    props.saveusercredentials(JSON.stringify(data));
    setstate({
      ...state,
      firebaseUser: firebaseUser,
      customObject: data,
    });
  };

  return (
    <>
      <div className="PasswordResetPage login-btn-wrapper">
        <div id="recaptcha-container"></div>
        {state.isloading ? (
          <LoadingIndicator />
        ) : state.error ? (
          <StatusView
            classname="root-error"
            message="Something went wrong!"
            type="error"
            showCloseButton={false}
          />
        ) : (
          <div className="login-content">
            <img src="/assets/images/logo-new.svg" alt="" />
            <div className="login-box">{getView()}</div>
          </div>
        )}
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      savenorthwelluserobj: actions.savenorthwelluserobj,
      saveusercredentials: actions.saveusercredentials,
    },
    dispatch
  );
};

export default withRouter(
  connect("", mapDispatchToProps)(React.memo(PasswordResetPage))
);
